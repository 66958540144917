.modal-backdrop-opacity {
  opacity: 0.8 !important;
}

.modal-width {
  max-width: 90% !important;
}

.card-body {
  background-color: #242928 !important;
  border-radius: 0.375rem;
}

.card-header {
  background-color: #242928 !important;
  border-radius: 0.375rem;
}

.card {
  /* border: 1px solid #737977 !important; */
  border: none !important;
  background-color: #242928 !important;
}

.textWhite {
  color: #8898aa !important;
}

.textBg {
  /* background-color: #737977 !important; */
  background-color: #19312e !important;
  color: white !important;
}

.textBg-disabled {
  /* background-color: #737977 !important; */
  /* background-color: #19312e !important; */
  /* color: white !i mportant; */
  background-color: #19312e !important;
  cursor: not-allowed !important;
  border-color: rgba(50, 151, 211, 0.25);
}

table.table-custom th,
table.table-custom td {
  border-color: #737977 !important;
}

.custom-padding {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

/* .table-custom table, th, td  {
  border-color: #737977 !important;
} */

.modal-bg {
  background-color: #263937 !important;
}

.progress-bg {
  color: black !important;
}

/* .column-width {
  max-width: 150px;
  white-space: normal !important;
} */

.btn-custom-hover:hover .text-hover {
  /* background-color: #293d39 !important; */
  color: #c1ffa6 !important;
  /* border: 2px solid rgb(76, 146, 159); */
}

.table-data-font {
  font-size: 14px !important;
}

.neumorphic {
  border-radius: 50px;
  background: #19312e;
  box-shadow: 21px 21px 43px #152a27, -21px -21px 43px #1d3835;
}

.current-bar .progress-bar {
  background-color: #a7ce39;
}

.previous-bar .progress-bar {
  background-color: #0c8ea5;
}

.table-td-color {
  color: rgb(200, 200, 200) !important;
}

.filter-label-font-size {
  font-size: 18px;
}

.bg-threshold {
  background-color: #ff0000 !important;
}

@media print {
  #no-print {
    display: none;
  }
  #page-top-margin {
    margin-top: 50px;
  }
  #page-margin-top-1 {
    margin-top: 120px;
  }
  #page-margin-top-2 {
    margin-top: 350px;
  }
  #page-margin-bottom-1 {
    margin-bottom: 80px;
  }
}

.recipe-height-custom {
  height: 100% !important;
  min-height: 500px !important;
}

.top-op-bg {
  background-color: #19312e !important;
}

.table-op-bg-good {
  background-color: #25a18e !important;
}

.table-op-bg-satisfactory {
  background-color: #77625c !important;
}

.table-op-bg-poor {
  background-color: #db3a34 !important;
}

.required-label {
  color: #db3a34;
}

.text-color-mw {
  color: #a7cd39 !important;
}
