/* Sidebar.css */
.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 30px; /* Slightly larger to include the icon */
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.sidebar-indicator {
  position: absolute;
  left: 5px; /* Adjust for alignment */
  font-size: 1.5rem; /* Icon size */
  color: #a7ce39; /* Icon color */
  cursor: pointer;
  transition: transform 0.3s ease;
}

.sidebar-indicator:hover {
  transform: scale(1.2); /* Slight zoom on hover */
}

.sidebar {
  position: absolute;
  top: 0;
  left: -350px; /* Hidden state */
  width: 350px;
  height: 100%;
  background-color: #19312e; /* Match the theme */
  color: white;
  padding: 20px;
  transition: left 0.3s ease; /* Smooth slide-in effect */
  overflow-y: auto;
  border-right: 1px solid #4c545e;
}

.sidebar-visible {
  left: 0; /* Visible state */
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 15px;
  cursor: pointer;
  color: #ecf0f1; /* Text color */
}

.sidebar li:hover {
  /* text-decoration: underline; */
  color: #c1ffa6 !important;
}

/* Menu Container */
.sidebar-menu {
  padding: 0;
  margin: 0;
}

.menu-item {
  margin: 10px 0;
  padding: 5px;
  border: solid 1px #555050;
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.menu-item:hover {
  transform: scale(1.1); /* Slight scale on hover */
}

.menu-icon {
  font-size: 28px; /* Icon size */
  /* margin-bottom: 8px; */
  color: #ecf0f1; /* Light color for icons */
}

.menu-text {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
